<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout column fill-height>
		<w-flex shrink>
			<w-text-info class="mt-2 mx-2" :text="$t('projects.catalog_services_selction')" />
		</w-flex>
		<w-flex shrink>
			<w-search-input v-model="search" class="mb-2 mx-2" :label="$t('actions.search')" />
		</w-flex>
		<w-flex scroll-y>
			<w-layout v-for="category in filteredCategories" :key="category.id" column>
				<w-flex>
					<w-divider />
					<w-layout align-center row>
						<v-avatar class="ma-2" :color="category.color" size="32px">
							{{ category.abbreviation }}
						</v-avatar>
						<w-flex v-if="search" subheading v-html="prettifyTitle(category)" />
						<w-flex v-else subheading v-text="category.name" />
					</w-layout>
					<w-divider />
				</w-flex>
				<w-checkbox
					v-for="catalogService in category.catalog_services"
					:key="catalogService.id"
					:value="catalogServiceIds.includes(catalogService.id)"
					class="ml-4 my-1"
					:label="catalogService.title"
					@input="onInput($event, catalogService)"
				>
					<template v-if="search" v-slot:label>
						<w-flex v-html="prettifyTitle(catalogService)" />
					</template>
				</w-checkbox>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'
import Category from '@/classes/Offers/Category'

export default {
	name: 'CatalogServicesSelector',
	mixins: [ProjectsManagerModuleGuard],
	props: {
		value: {
			default: () => [],
			required: false,
			type: Array
		}
	},
	data: function () {
		return {
			categories: [],
			search: '',
			tree: []
		}
	},
	computed: {
		catalogServiceIds: function () {
			return this.value.map(cs => cs.id)
		},
		filteredCategories: function () {
			if (!this.search) {
				return this.categories
			}

			const regExp = this.searchRegExp

			return this.categories
				.filter(cat => {
					if (cat.name.match(regExp)) {
						return true
					}

					return cat.catalog_services.some(cs => cs.title.match(regExp))
				})
				.map(cat => {
					if (cat.name.match(regExp)) {
						return cat
					}

					const category = cat.clone()
					category.catalog_services = category.catalog_services.filter(cs => cs.title.match(regExp))

					return category
				})
		},
		searchRegExp: function () {
			return new RegExp(this.search.replace(/[-[\]{}()+?.,\\^$|#\s]/g, '\\$&').replace(/\*/g, '.*'), 'gi')
		}
	},
	watch: {
		accountingFirmId: {
			handler: 'listCategories',
			immediate: true
		}
	},
	methods: {
		addCatalogService: function (catalogService) {
			const catalogServices = this.value
			catalogServices.push(catalogService)

			return catalogServices
		},
		listCategories: function () {
			this.service.listCategories(['catalog_services']).then(categories => {
				this.categories = categories
			})
		},
		onInput: function (toggleValue, catalogService) {
			let newValue
			if (toggleValue) {
				newValue = this.addCatalogService(catalogService)
			} else {
				newValue = this.removeCatalogService(catalogService)
			}

			this.$emit('input', newValue)
		},
		prettifyTitle: function (objectWithTitle) {
			let catalogService, category
			if (Category.prototype.isPrototypeOf(objectWithTitle)) {
				category = objectWithTitle
			} else {
				catalogService = objectWithTitle
				category = catalogService.category
			}

			if (catalogService) {
				return catalogService.title.replace(this.searchRegExp, `<b>$&</b>`)
			}
			return category.name.replace(this.searchRegExp, `<b>$&</b>`)
		},
		removeCatalogService: function (catalogService) {
			const catalogServices = this.value
			const catalogServiceIndex = catalogServices.findIndex(cs => cs.id === catalogService.id)

			if (catalogServiceIndex >= 0) {
				catalogServices.splice(catalogServiceIndex, 1)
			}

			return catalogServices
		}
	}
}
</script>
